module.exports = [
   {
      "id": "baker-start-date",
      "type": "start-date",
      "default": true,
      "exclude": [
         "/apply/",
         "/thank-you/"
      ]
   }
]